<template>
  <div>
    <section v-if="false">
      <b-container>
        <h1>Administrator instellingen</h1>
        <b-card>
          <validation-observer ref="settings">
            <b-row>
              <b-col cols="12" sm="6" md="4">
                <label class="d-flex">
                  Standaard rentepercentage
                </label>
                <b-input-group append="%">
                  <b-form-input type="number" placeholder="4" />
                </b-input-group>
              </b-col>

              <b-col cols="12" sm="6" md="4">
                <label class="d-flex">
                  Minimum leeftijd
                </label>
                <b-input-group append="jaar">
                  <b-form-input type="number" placeholder="18" />
                </b-input-group>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12" sm="6" md="4">
                <label class="d-flex">
                  Aflossingsperiode
                </label>
                <b-input-group append="maanden">
                  <b-form-input type="number" placeholder="360" />
                </b-input-group>
              </b-col>
              <b-col cols="12" sm="6" md="4" class="align-self-end">
                <b-button variant="primary" @click="updateSettings">Wijzig</b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-container>
    </section>

    <section>
      <b-container>
        <h1>Vrijstellingen</h1>
        <b-card>
          <validation-observer ref="exemptions">
            <b-row class="mt-1">
              <b-col cols="12">
                <label class="">
                  Vrijstelling (deze bedragen zijn kiesbaar in het schenkingsformulier)
                </label>
                <div>
                  <div v-for="(exemption, index) in exemptions" :key="index">
                    <b-row class="mt-1">
                      <b-col cols="3">
                        <validation-provider #default="{ errors }" name="vrijstelling" :rules="{ required: true, regex: '^[0-9,]+$' }">
                          <b-form-input v-model="exemptions[index].amount" placeholder="bedrag" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col cols="3">
                        <validation-provider #default="{ errors }" name="Omschrijving" :rules="{ required: true }">
                          <b-form-input v-model="exemptions[index].description" placeholder="omschrijving" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col>
                        <b-button variant="primary" class="mr-1" @click="addField">Nieuw</b-button>
                        <b-button variant="danger" v-if="exemptions.length > 1" @click="removeField(index)">Verwijder</b-button>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12" sm="6" md="4" class="align-self-end">
                <b-button variant="primary" @click="updateSettings">Wijzig</b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-container>
    </section>

    <section>
      <b-container>
        <h1>Partnercode</h1>
        <b-card>
          <validation-observer ref="partnerCodes">
            <b-row>
              <b-col cols="12" md="4" xl="">
                <label class="d-flex">
                  Partnercode
                </label>
                <validation-provider #default="{ errors }" name="partnercode" rules="required">
                  <b-input-group>
                    <b-form-input v-model="newPartner.partnercode" type="text" placeholder="CONSUMENTENBOND" :state="errors.length > 0 ? false : null" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" xl="">
                <label class="d-flex">
                  Waarde
                </label>
                <validation-provider #default="{ errors }" name="partnercode" rules="required">
                  <b-input-group>

                    <b-form-input v-model="newPartner.value" type="number" />
                    <b-input-group-append is-text>
                      <span class="mr-1">€</span>
                      <b-form-checkbox v-model="newPartner.type" switch class="mr-n2">
                        <span class="sr-only">Switch for previous text input</span>
                      </b-form-checkbox>
                      <span class="ml-2">%</span>
                    </b-input-group-append>

                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" xl="">
                <label class="d-flex">
                  Periodieke korting?
                </label>
                <b-form-checkbox v-model="newPartner.recurring" />
              </b-col>

              <b-col cols="12" md="4" xl="">
                <label class="d-flex">
                  Limietdatum
                </label>
                <cleave placeholder="01-12-2022" v-model="newPartner.expired" :options="maskOptions.date" class="form-control" :raw="false" />
              </b-col>

              <b-col cols="12" md="4" xl="" class="align-self-end">
                <b-button @click="storePartnerCode" variant="primary">Voeg toe</b-button>
              </b-col>
            </b-row>
          </validation-observer>

          <b-table class="mt-1" responsive borderless small :items="partnercodes" :fields="partnercodesTable.fields">
            <template #cell(type)="row">
              {{ row.item.type === 1 ? "Euro" : "Procent" }}
            </template>

            <template #cell(recurring)="row">
              {{ row.item.recurring === "0" ? "Eenmalig" : "Periodiek" }}
            </template>

            <template #cell(action)="row">
              <b-button variant="danger" @click="deletePartnerCode({ id: row.item.id, index: row.index })">
                Verwijder
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-container>
    </section>

    <section>
      <b-container>
        <h1>Gebruikers</h1>
        <b-card>
          <div class="">
            <h2>Search</h2>
            <div class="d-flex ">

              <b-form-input id="filter-input" v-model="adminFilter" type="search" placeholder="Type to Search"></b-form-input>
              <div class="ml-1">
                <b-button v-if="$auth.db.impersonated_by" @click="cancelImpersonation">Annuleer inloggen als</b-button>
              </div>
            </div>
          </div>
          <b-table class="mt-2" responsive borderless :items="admins" :fields="adminTable.fields" :filter="adminFilter" :filter-included-fields="['fullname', 'email']">
            <template #cell(id)="row">
              {{ row.index + 1 }}
            </template>

            <template #cell(action)="row">
              <router-link :to="{ name: 'admin-profile', params: { adminId: row.item.hashid } }">
                <b-button variant="primary">Bekijk</b-button>
              </router-link>
              <b-button variant="info" class="ml-1" @click="impersonateUser(row.item)">Inloggen als</b-button>
            </template>
          </b-table>
        </b-card>
      </b-container>
    </section>


  </div>
</template>

<script>
// import store from '@/store'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapActions, mapGetters } from "vuex"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave
  },
  data() {
    let minDate = new Date().toISOString().split('T')[0];

    return {
      maskOptions: {
        date: {
          date: true,
          datePattern: ['d', 'm', 'Y'],
          delimiter: "-",
          dateMin: minDate,
        },
      },
      exemptions: [],
      newPartner: {
        partnercode: "",
        value: "",
        type: false,
        expired: "",
        disabled: false,
        recurring: false,
      },
      partnercodesTable: {
        fields: [
          { key: 'name', label: 'Partnercode' },
          { key: 'value', label: 'Waarde' },
          { key: 'type', label: 'Eenheid' },
          {
            key: 'expired', label: 'Datum',
            formatter: (value) => {
              const date = new Date(value);
              return new Intl.DateTimeFormat('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' }).format(date);

            }
          },
          { key: 'recurring', label: 'Periodieke korting?' },
          { key: 'action', label: 'Actie', },
        ]
      },

      required,
      email,
      admins: [],
      adminFilter: null,
      adminTable: {
        fields: [
          { key: 'fullname', label: 'Naam' },
          { key: 'email', label: 'Email' },
          { key: 'action', label: 'Actie', },
        ]
      },
    }
  },
  mounted() {
    console.log("hello")
    if (this.$auth.hasRole(['superadmin']) === false) {
      this.$router.push("/")
    } else {
      this.getPartnerCodes();
      this.getExemptions()
      this.getAdmins()
    }
  },
  computed: {
    ...mapGetters({ partnercodes: "partnercodes/get" }),
  },
  methods: {
    ...mapActions({ getPartnerCodes: "partnercodes/all", addPartnerCode: "partnercodes/add", deletePartnerCode: "partnercodes/delete" }),

    storePartnerCode() {
      this.addPartnerCode({
        "name": this.newPartner.partnercode,
        "type": this.newPartner.type ? 0 : 1,
        "value": this.newPartner.value,
        "disabled": this.newPartner.disabled ? 1 : 0,
        "expired": this.newPartner.expired.split("-").reverse().map(Number).join("-"),
        "recurring": this.newPartner.recurring ? 1 : 0
      });
    },
    getExemptions() {
      let _this = this;
      this.$http.get("/settings/exemptions").then(response => {
        console.log(response);
        if (response.data.value) {
          _this.exemptions = JSON.parse(response.data.value);
        } else {
          _this.exemptions.push({ amount: "" })
        }
      });
    },
    addField() {
      this.exemptions.push({ amount: "" })
    },
    removeField(index) {
      this.exemptions.splice(index, 1)
    },
    updateSettings() {
      let formData = new FormData()

      formData.append("exemptions", JSON.stringify(this.exemptions))

      this.$http.post("/settings", formData).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'vrijstelling geupdate',
            text: "Gelukt",
            icon: 'InfoIcon',
            variant: 'success',
          },
        });
      });
    },
    getAdmins() {
      this.$http.get("/admin/admins").then(response => {
        if (response.data) {
          this.admins = response.data.users;
        }
      }).catch(err => {
        console.log(err)
      });
    },
    impersonateUser(user) {
      this.$http.post("/admin/users/impersonate", { user_id: user.hashid }).then(response => {
        if (response.data) {
          this.$router.push("/")
        }
      });
    },
    cancelImpersonation() {
      this.$http.post("/admin/users/impersonate", {
        user_id: this.$auth.db.impersonated_by.hashid
      }).then(response => {
        if (response.data) {
          this.$router.push("/")
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped></style>
